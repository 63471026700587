import { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import { Typography, Theme, Container, Tooltip } from "@mui/material"

import { makeStyles, withStyles } from "@mui/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadIcon from '@mui/icons-material/Upload';
import { RiDeleteBin6Line } from "react-icons/ri";

import "./pdfStyle.css"
import useModal from "../../../hooks/useModal"
import useAlert from "../../../hooks/useAlert"
import { IDocuments } from "../../../@types/Documents"
import RemoveDocumentLink from "../../../components/RemoveDocumentLink"
import { GetVacancyDocuments } from "../../../services/api"
import { swalAlert } from "../../../helpers/swalAlert"
import useDocumentLink from "../../../hooks/useDocumentLink"
import { getMostRecentDocument, statusText } from "../../../helpers/documentHelpers"
import GoBackHeader from "../../../components/GoBackHeader"
import useIsLinkRoute from "../../../hooks/useIsLinkRoute"

export default function DocumentAttach() {
  const smallMobile = useMediaQuery("(max-height:600px)")

  const bigScreenDevice = useMediaQuery("(min-width:600px)")

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'stretch',
      height: '100vh',
      paddingLeft: 0,
      paddingRight: 0,
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 447,
      marginTop: 24,
      marginLeft: 16,
      marginRight: 16,
      marginBottom: 67
    },
    text: {
      color: "#1D3054",
      lineHeight: 1,
      textAlign: "start",
      marginTop: !smallMobile ? 16 : 8,
    },
    documentName: {
      color: "#1D3054",
      fontWeight: 700,
      textAlign: "start",
      marginTop: !smallMobile ? 16 : 4,
      fontSize: bigScreenDevice ? 24 : "4vmax",
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: 16,
      borderRadius: 8,
      backgroundColor: '#fff',
      boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)",
      marginBottom: 8,
      marginTop: 12,
    },
    centralTextCard: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 0',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginLeft: 8,
      color: '#1D3054',
      fontWeight: 400,
      fontSize: 14,
      gap: 4,
    },
    iconCard2: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#15803D",
      background: "#DCFCE7",
      justifyContent: "center",
    },
    iconCardAttached2: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#854D0E",
      background: "#FEF9C3",
      justifyContent: "center",
    },
    iconCardError2: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#B91C1C",
      background: "#FEE2E2",
      justifyContent: "center",
    },
    iconCardNotSent2: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      background: "#fff",
      justifyContent: "center",
    },
    buttonAttach: {
      display: 'flex',
      padding: '16px 0px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      alignSelf: 'stretch',
      marginTop: 4,
      width: '100%',
      height: 200,
      borderRadius: 8,
      cursor: 'pointer',
      border: '1px dashed var(--neutral-colors-cinza-300, #B1B3B3)',
      textAlign: 'center',
      color: '#1D3054',
      marginBottom: 24,
    },
    iconDelete: {
      marginLeft: 'auto',
      width: 24,
      height: 24,
      borderRadius: 50,
      padding: 4,
      border: '0.5px solid #B1B3B3',
      color: '#B91C1C',
      '&:hover': {
        cursor: 'pointer'
      },
    },
    messageDocuments2: {
      fontSize: 12,
      lineHeight: "18px",
      display: 'flex',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
      gap: 4,
      alignSelf: 'stretch',
      borderRadius: 8,
    },
  }))

  const classes = useStyles()
  const navigate = useNavigate()
  const { modal, setModal } = useModal()
  const { alert, setAlert } = useAlert()
  const [documentRemoved, setDocumentRemoved] = useState(false);
  const [documents, setDocuments] = useState<IDocuments[]>([])
  const [document, setDocument] = useState<IDocuments>({} as IDocuments)
  const { user } = useDocumentLink();
  const isLinkRoute = useIsLinkRoute();

  useEffect(() => {
    setDocument(JSON.parse(localStorage.getItem("documentToUpload") as string))

    if (!localStorage.getItem("permissaoDispositivo"))
      setModal({ ...modal, devicePermission: true })

    if (localStorage.getItem("permissaoDispositivo") === "Não")
      setModal({ ...modal, devicePermission: true })
  }, [])

  const documentType = (document: IDocuments) => {
    if (
      ((document.statusDoUsuario === "Documento Enviado" ||
        (document.statusDoUsuario === "Documento Anexado" && document.documentosDigitais.length > 0) ||
        (document.statusDoUsuario === "Documento Pendente" &&
          document.justificativa !== null &&
          document.justificativa !== "")) &&
        !document.nome.includes("Outros")) ||
      (document.statusDoUsuario === "Documento Anexado" && document.documentosDigitais.length > 0) ||
      document.statusDoUsuario === "Documento Enviado"
    ) {
      return;
    }

    const targetRoute = isLinkRoute ? '/link/documentos/enviar' : '/auth/documentos/enviar';
    localStorage.setItem("documentToUpload", JSON.stringify(document));
    navigate(targetRoute);
  }


  const mostRecentDocument = getMostRecentDocument(document);

  const statusLabel = (document.statusDoUsuario === "Documento Enviado")
    ? "Enviado"
    : (document.statusDoUsuario === "Documento Pendente")
      ? "Reprovado"
      : statusText[mostRecentDocument?.status as keyof typeof statusText];

  const documentCardColor = () => {
    const status = mostRecentDocument?.status || '';
    const statusEnviado = document.statusDoUsuario || '';
    if (status === "Aprovado Textract" || status === "Aprovado" || status === "Aprovado Sem Textract" || statusEnviado === "Documento Enviado") {
      return classes.iconCard2;
    } else if (status === "Aguardando Textract" || status === "Pendente") {
      return classes.iconCardAttached2;
    } else if (status === "Reprovado" || (statusEnviado === "Documento Pendente" && document.justificativa !== null && document.justificativa !== "")) {
      return classes.iconCardError2;
    } else {
      return classes.iconCardNotSent2;
    }
  }

  const getDocuments = async () => {
    setAlert({ ...alert, waitingResponse: true });
    let response;
    if (isLinkRoute) {
      const nrChamado = user.nrChamado || sessionStorage.getItem("nrChamadoLink");
      if (!nrChamado) {
        swalAlert("error", "Vaga não encontrada, entre novamente pelo Link enviado para você", "Ok");
        navigate("/link/carregar/")
        return;
      }
      response = await GetVacancyDocuments(nrChamado);
    } else {
      let vacancyNumber = JSON.parse(localStorage.getItem("selectedVacancy") as string).nrChamado;
      response = await GetVacancyDocuments(vacancyNumber);
    }

    const targetRoute = isLinkRoute ? '/link/documentos' : '/auth/vagas-em-andamento';

    if (response?.status >= 200 && response?.status < 300) {
      setDocuments(response.data.listaDocumentosFormatada);
      setAlert({ ...alert, waitingResponse: false });
    } else {
      swalAlert("error", response, "TENTAR NOVAMENTE");
      setTimeout(() => navigate(targetRoute), 2000);
    }
    setAlert({ ...alert, waitingResponse: false });
  }

  const handleRemoveDocument = (document: IDocuments) => {
    let newDocs = [...documents]
    newDocs.forEach(temp => (temp.openTooltip = false))
    setDocuments(newDocs)
    localStorage.setItem("documentToUpload", JSON.stringify(document))
    setModal({ ...modal, removeDocument: true })
  }

  const renderStatusIcon = () => {
    if (mostRecentDocument?.status === "Aprovado" ||
      mostRecentDocument?.status === "Aprovado Sem Textract" ||
      mostRecentDocument?.status === "Aprovado Textract" ||
      document?.statusDoUsuario === "Documento Enviado") {
      return <CheckIcon style={{ fontSize: 16, marginRight: 8 }} />;
    } else if (mostRecentDocument?.status === "Aguardando Textract") {
      return <UploadIcon style={{ fontSize: 16, marginRight: 8 }} />;
    } else {
      return <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 8 }} />;
    }
  };

  return (
    <>
      <Container maxWidth="xs" className={classes.container}>
        <GoBackHeader
          onGoBack={() => (isLinkRoute ? navigate("/link/documentos") : navigate("/auth/documentos"))}
        />
        <div className={classes.content}>

          <Typography className={classes.documentName}>{document?.nome}</Typography>

          <Typography variant='body1' className={classes.text}>
            {document?.descricao}
          </Typography>
          <div
            onClick={() => documentType(document)}
            className={classes.buttonAttach}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                documentType(document);
              }
            }}
          >
            <AddCircleIcon style={{ fontSize: 50, color: '#1D3054' }} />
            <h2>Adicionar documento</h2>
            <p>Clique aqui para fazer o envio dos documentos</p>
          </div>


          {mostRecentDocument?.status === "Reprovado" && (
            <div className={classes.messageDocuments2} style={{ color: '#b91c1c', backgroundColor: '#FEE2E2' }}>
              <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 8 }} />
              <text>
                O documento <strong>{document.nome}</strong> foi <strong>recusado</strong>, ele não está legível ou não é o documento correto, por favor delete este documento e faça um novo envio.
              </text>
            </div>
          )}
          {document?.statusDoUsuario === "Documento Pendente" && document.justificativa !== null && document.justificativa !== "" && (
            <div className={classes.messageDocuments2} style={{ color: '#b91c1c', backgroundColor: '#FEE2E2' }}>
              <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 8 }} />
              <span>
                O documento <strong>{document.nome}</strong> foi <strong>recusado</strong> pelo seguinte motivo, <strong>{document.justificativa}</strong>. Por favor delete este documento e faça um novo envio.
              </span>
            </div>
          )}

          {mostRecentDocument?.status === "Aguardando Textract" && (
            <div className={classes.messageDocuments2} style={{ backgroundColor: '#FEF9C3', color: '#854D0E', }}>
              <UploadIcon style={{ fontSize: 16, marginRight: 8 }} />
              <text>
                Estamos <strong> processando o documento</strong> <strong>{document.nome}</strong>, para verificar se está tudo correto. Aguarde o processamento do arquivo, ou remova o documento para realizar um novo envio.
              </text>
            </div>
          )}

          {(
            document.statusDoUsuario === "Documento Enviado" ||
            document.statusDoUsuario === "Documento Anexado" ||
            (document.statusDoUsuario === "Documento Pendente"
              && document.justificativa !== null && document.justificativa !== "")
          ) && !documentRemoved && (
              <div className={classes.card}>
                <AttachFileIcon style={{ marginRight: 8, color: "#1D3054", backgroundColor: "#E8E8E8", width: 32, height: 32, padding: 8, borderRadius: 8, }} />
                <Typography variant="body2" className={classes.centralTextCard} >
                  {document?.nome}
                  {statusLabel && (
                    <Typography variant="body2" style={{
                      fontSize: 12,
                      display: 'flex',
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 4,
                      paddingBottom: 4,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 50,
                    }} className={documentCardColor()}>
                      {renderStatusIcon()}
                      {statusLabel}
                    </Typography>
                  )}
                </Typography>
                {(document.statusDoUsuario === "Documento Anexado" || document.statusDoUsuario === "Documento Pendente" || mostRecentDocument?.status === "Pendente") && (
                  <RiDeleteBin6Line
                    className={classes.iconDelete}
                    onClick={() => handleRemoveDocument(document)}
                  />
                )}
              </div>
            )}
        </div>

        {(document.statusDoUsuario === "Documento Anexado" || document.statusDoUsuario === "Documento Pendente" || mostRecentDocument?.status === "Pendente") && (
          <RemoveDocumentLink
            open={modal.removeDocument}
            getDocuments={getDocuments}
            close={() => setModal({ ...modal, removeDocument: false })}
            document={JSON.parse(localStorage.getItem("documentToUpload") as string)}
            setDocumentRemoved={setDocumentRemoved}
          />
        )}

      </Container>
    </>
  )
}
